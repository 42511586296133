@media (min-width: 767px) {
  .navbar-nav .dropdown-menu .caret {
    transform: rotate(-90deg);
  }
}
body a {
  color: #2e4595;
}
body a:hover {
  color: #4965c7;
  text-decoration: underline;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #283c82;
}
h1 {
  margin-top: 0px;
  margin-bottom: 1em;
  font-size: 2em;
  font-family: "Times New Roman";
}
h3.author {
  margin-top: 0px;
}
#background {
  background-image: url("/f/se001.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  opacity: 0.5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}
#maincont {
  background-image: url("/f/se001.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}
.navbar {
  margin-bottom: 0px;
  border-radius: 0px;
}
#portalNav-row .navbar-inverse {
  background-color: #b09367;
  border-color: #b09367;
}
#portalNav-row .navbar-inverse a {
  color: white;
}
#portalNav-row .navbar-inverse ul.navbar-nav a {
  padding: 15px 10px;
}
#portalNav-row .navbar-inverse ul.navbar-nav li.open > a {
  background-color: #91754b;
}
#portalNav-row .navbar-inverse ul[class*=ul_level] {
  background-color: white;
}
#portalNav-row .navbar-inverse ul[class*=ul_level] a {
  color: #91754b;
  padding: 3px 20px !important;
}
#portalNav-row .navbar-inverse .navbar-collapse {
  border-color: #91754b;
}
#portalNav-row .navbar-inverse .navbar-toggle {
  border-color: #91754b;
}
#portalNav-row .navbar-inverse .navbar-toggle:hover {
  background-color: #91754b;
}
#portalNav-row span.search {
  float: right;
  width: 120px;
  padding: 8px 0;
  margin-right: -15px;
}
#portalNav-row span.search .ya-site-form__submit {
  display: none;
}
#portalNav-row span.search .ya-site-form__input-text {
  border-radius: 2px;
}
#portalNav-row span.search .ya-site-form__input-text::-webkit-input-placeholder {
  color: #91754b;
}
#portalNav-row span.search .ya-site-form__input-text:-moz-placeholder {
  /* FF 4-18 */
  color: #91754b;
}
#portalNav-row span.search .ya-site-form__input-text::-moz-placeholder {
  /* FF 19+ */
  color: #91754b;
}
#portalNav-row span.search .ya-site-form__input-text:-ms-input-placeholder {
  /* IE 10+ */
  color: #91754b;
}
#siteNav-row {
  font-size: 1.2em;
}
#siteNav-row .navbar-inverse {
  background-color: rgba(40, 60, 130, 0.8);
  border-color: #283c82;
}
#siteNav-row .navbar-inverse a {
  color: white;
}
#siteNav-row .navbar-inverse ul.navbar-nav li.open > a {
  background-color: #344ea9;
}
#siteNav-row .navbar-inverse ul[class*=ul_level] {
  background-color: white;
}
#siteNav-row .navbar-inverse ul[class*=ul_level] a {
  color: #2e4595;
}
#siteNav-row .navbar-inverse .navbar-collapse {
  border-color: #344ea9;
  border-top: none;
}
#siteNav-row .navbar-inverse .navbar-toggle {
  border-color: #344ea9;
}
#siteNav-row .navbar-inverse .navbar-toggle:hover {
  background-color: #344ea9;
}
#site-title-row h1 {
  text-transform: uppercase;
  font-family: Times New Roman;
  text-shadow: 2px 2px 3px black;
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
}
#site-title-row p {
  color: white;
  text-shadow: 2px 2px 3px black;
}
.content {
  opacity: 0.92;
  padding-top: 40px;
  background-color: white;
  min-height: 60vh;
}
.navbar-brand {
  padding: 2px 15px;
}
footer {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba(40, 60, 130, 0.8);
  color: white;
  font-size: 0.8em;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
footer a {
  color: white;
}
footer a:hover {
  color: white;
}
footer .address,
footer .author {
  text-align: right;
}
.after-footer {
  position: relative;
  margin-top: -80px;
  height: 101vh;
  background-image: url("/f/se001.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}
.sidenav,
.text-left {
  padding-bottom: 2em;
}
.numbers span:first-child {
  margin-left: 0.7em;
}
.number-list p.year {
  margin-bottom: 0px;
  margin-top: 10px;
  background-color: #97a7df;
  color: white;
  padding-left: 0.7em;
}
.number-list .number {
  display: inline-block;
  padding: 0px 5px 0px 0px;
}
ul.lang-menu li.current a {
  padding-top: 0px;
  padding-bottom: 0px;
}
ul.lang-menu li.current:not(.open) a {
  color: white;
}
.logo {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 30px;
}
.redkolegia td {
  padding: 10px;
}
.redkolegia .photo {
  width: 75px;
  height: 75px;
  background-size: cover;
  border-radius: 37.5px;
}
.redkolegia .name {
  font-weight: bold;
}
.redkolegia .name span.surname {
  text-transform: uppercase;
}
